import { getEnums } from '@/utils/utils'

export const fingerPrintColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '印章名称',
    dataIndex: 'sealName',
    key: 'sealName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'sealName' }
    // width: 150
  },
  {
    title: '设备编号',
    dataIndex: 'number',
    key: 'number',
    ellipsis: true,
    align: 'left'
  },
  {
    title: '盖印人',
    dataIndex: 'staffName',
    key: 'staffName',
    ellipsis: true,
    align: 'left'
  },
  {
    title: '部门',
    dataIndex: 'departmentName',
    key: 'departmentName',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'departmentName' },
    width: 190
  },
  {
    title: '实际盖印次数',
    dataIndex: 'practicalNumber',
    key: 'practicalNumber',
    align: 'left',
    slots: { customRender: 'practicalNumber' }
    // width: 100
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    key: 'startTime',
    ellipsis: true,
    align: 'left'
  },
  {
    title: '结束时间',
    dataIndex: 'finishTime',
    key: 'finishTime',
    ellipsis: true,
    align: 'left'
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 120
  }
]

//用印记录【政务版】
export const baseColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '流程主题',
    dataIndex: 'fileName',
    key: 'fileName',
    align: 'left',
    ellipsis: true,
    width: 100
  },
  {
    title: '申请人',
    dataIndex: 'staffName',
    key: 'staffName',
    align: 'left',
    ellipsis: true,
    width: 100
  },
  {
    title: '申请印章',
    dataIndex: 'sealNames',
    key: 'sealNames',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'sealNames' },
    width: 100
  },
  {
    title: '申请次数',
    dataIndex: 'applySums',
    key: 'applySums',
    align: 'left',
    width: 100
  },
  {
    title: '实际盖印次数',
    dataIndex: 'practicalNumber',
    key: 'practicalNumber',
    align: 'left',
    slots: { customRender: 'practicalNumber' },
    width: 100
  },
  {
    title: '盖印时间',
    dataIndex: 'applyTime',
    key: 'applyTime',
    align: 'left',
    ellipsis: true,
    sorter: true,
    slots: { customRender: 'applyTime' },
    width: 150
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 150
  }
]
//用印记录【企业版】
export const companyColumns = [
  {
    title: '序号',
    dataIndex: 'id',
    key: 'id',
    align: 'left',
    slots: { customRender: 'id' },
    width: 100
  },
  {
    title: '流程主题',
    dataIndex: 'fileName',
    key: 'fileName',
    align: 'left',
    // ellipsis: true,
    width: 218,
    slots: { customRender: 'fileName' }
  },

  {
    title: '申请人',
    dataIndex: 'staffName',
    key: 'staffName',
    align: 'left',
    width: 205,
    slots: { customRender: 'staffName' }
  },
  {
    title: '盖印人',
    dataIndex: 'sealUser',
    key: 'sealUser',
    align: 'left',
    width: 205,
    slots: { customRender: 'sealUser' }
  },
  // {
  //   title: '部门',
  //   dataIndex: 'departmentName',
  //   key: 'departmentName',
  //   align: 'left',
  //   ellipsis: true,
  //   slots: { customRender: 'departmentName' },
  //   width: 190
  // },
  {
    title: '印章名称',
    dataIndex: 'sealNames',
    key: 'sealNames',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'sealNames' },
    width: 190
  },
  {
    title: '申请次数',
    dataIndex: 'applySums',
    key: 'applySums',
    align: 'left',
    width: 100
  },
  {
    title: '业务类型',
    dataIndex: 'processType',
    key: 'processType',
    align: 'left',
    width: 100,
    // slots: { customRender: 'processName' }
    customRender: ({ text }) => getEnums('PROCESS_TYPE', text)?.desc || '--'
  },
  {
    title: '流程名称',
    dataIndex: 'processName',
    key: 'processName',
    align: 'left',
    // ellipsis: true,
    width: 218,
    slots: { customRender: 'processName' }
  },
  {
    title: '实际盖印次数',
    dataIndex: 'practicalNumber',
    key: 'practicalNumber',
    align: 'left',
    slots: { customRender: 'practicalNumber' },
    width: 100
  },
  {
    title: '盖印时间',
    dataIndex: 'sealTime',
    key: 'sealTime',
    align: 'left',
    ellipsis: true,
    sorter: true,
    slots: { customRender: 'sealTime' },
    width: 180
  },
  {
    title: '流程状态',
    dataIndex: 'sealStatus',
    key: 'sealStatus',
    align: 'left',
    slots: { customRender: 'sealStatus' },
    width: 100
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 120
  }
]
